jQuery(document).ready(function ($) {

	$('.recherche').on('click', function() {
		setTimeout(function() { $('#recherche #s').focus() }, 300);
	});

	
	/* Page presentation timeline */

	$('#timeline .bloc_timeline:even').addClass('uk-animation-slide-left');
	$('#timeline .bloc_timeline:odd').addClass('uk-animation-slide-right');

	/* ANIMATION LIGNES */

	if ($(window).width() > 1200) {
		// ANimation bar scroll
		const controller = new ScrollMagic.Controller();

		// build scenes using a loop
		$('.bar_horizontal').each(function (i) {
			let thisLine = $(this);

			var action = gsap
				.timeline()
				.to(thisLine, { duration: 1.5, width: '100%', ease: '' });

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(action)
				.addTo(controller);
		});

		$('.bar_vertical').each(function (i) {
			let thisLine = $(this);

			var action = gsap
				.timeline()
				.to(thisLine, { duration: 1.5, height: '100%', ease: '' });

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(action)
				.addTo(controller);
		});
	}

	if ($(window).width() > 1200) {
		/*   FIN ANIMATION LIGNES */

		const controllerOpacity = new ScrollMagic.Controller();

		$('.anim_opacity').each(function (i) {
			let thisOpacity = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisOpacity, { duration: 1.5, autoAlpha: 0, ease: '' })
				.set(thisOpacity, { autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
					triggerHook: 0,
					offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerOpacity);
		});



		const controllerOpacityNoReverse = new ScrollMagic.Controller();
		$('.anim_opacity_noreverse').each(function (i) {
			let thisOpacity = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisOpacity, { duration: 1.5, autoAlpha: 0, ease: '' })
				.set(thisOpacity, { autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
					triggerHook: 0,
					offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerOpacityNoReverse);
		});

		/* ANIMATION BLOCS DIRECTION */

		const controllerBlocLeft = new ScrollMagic.Controller();

		$('.anim_bloc_left').each(function (i) {
			let thisBlocLeft = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisBlocLeft, {
					duration: 0.9,
					x: '-100%',
					ease: '',
				})
				.set(thisBlocLeft, { x: '0', autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerBlocLeft);
		});

		const controllerBlocRight = new ScrollMagic.Controller();

		$('.anim_bloc_right').each(function (i) {
			let thisBlocRight = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisBlocRight, {
					duration: 1.5,
					x: '50%',
					autoAlpha: 0,
					ease: '',
				})
				.set(thisBlocRight, { x: '0', autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerBlocRight);
		});

		const controllerBlocTop = new ScrollMagic.Controller();

		$('.anim_bloc_top').each(function (index, element) {
			let thisBlocTop = $(this);

			var actionTop = gsap.timeline().from(thisBlocTop, 0.5, {
				y: (index + 1) * 100,
				opacity: 0,
				delay: index * 0.1,
			});

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})

				.setTween(actionTop)
				.addTo(controllerBlocTop);
		});

		/* FIN ANIMATION BLOCS DIRECTION */

		/* ANIMATION BLOCS WIDTH */

		const controllerWidthLeft = new ScrollMagic.Controller();

		$('.anim_width_left').each(function (i) {
			let thisWidthLeft = $(this);

			var actionLeft = gsap
				.timeline()

				.from(thisWidthLeft, { duration: 1, width: '0px', ease: '' })
				.set(thisWidthLeft, { width: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerWidthLeft);
		});

		const controllerWidthRight = new ScrollMagic.Controller();

		$('.anim_width_right').each(function (i) {
			let thisWidthRight = $(this);

			var actionLeft = gsap
				.timeline()

				.from(thisWidthRight, { duration: 1, width: '0%', ease: '' })
				.set(thisWidthRight, { width: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerWidthRight);
		});

		const controllerHeightTop = new ScrollMagic.Controller();

		$('.anim_height_top').each(function (i) {
			let thisHeightTop = $(this);

			var actionTop = gsap
				.timeline()

				.from(thisHeightTop, { duration: 1, height: '0px', ease: '' })
				.set(thisHeightTop, { height: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionTop)
				.addTo(controllerHeightTop);
		});

		const controllerHeightTopNoReverse = new ScrollMagic.Controller();

		$('.anim_height_top_noreverse').each(function (i) {
			let thisHeightTop = $(this);

			var actionTop = gsap
				.timeline()

				.from(thisHeightTop, { duration: 0.5, height: '0px', ease: '' })
				.set(thisHeightTop, { height: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionTop)
				.addTo(controllerHeightTopNoReverse);
		});

		/* FIN ANIMATION BLOCS WIDTH */
	}


	//Animation svg au scroll



	

	/*    var controllerSVG = new ScrollMagic.Controller();

    function pathPrepare($el) {
    var lineLength = $el[0].getTotalLength();
    $el.css("stroke-dasharray", lineLength);
    $el.css("stroke-dashoffset", lineLength);
    }



    var $route = $(".line-animated path");

    pathPrepare($route);

    var route_1 = new TimelineMax()
    .add(TweenMax.to($route, 0.9, {
        strokeDashoffset: 0,
        ease: Linear.easeNone
    }));

    var scene = new ScrollMagic.Scene({
        triggerElement: this,
        duration: 900,
        tweenChanges: true
    })
    .setTween(route_1)
    .addTo(controllerSVG); */

	/*   var tl = new TimelineMax();
    tl.add("reveal");
    tl.to(".cls-1",2,{opacity:1 });
    tl.to(".cls-2",2,{opacity:1 });
    tl.to(".cls-3",2,{opacity:1 });
    tl.to(".cls-4",2,{opacity:1 });
    //create a tween that has a duration the same as the timeline and put it at a time of 0
    tl.fromTo("#rejoindre", tl.duration(), {drawSVG:0}, {drawSVG:"100%"}, 0);
 */


/* 	var path = document.querySelectorAll('#footer_top path'),
		percentScroll;

	$(path).each(function () {
		this.style.strokeDasharray = this.getTotalLength();
		this.style.strokeDashoffset = this.getTotalLength();
	});

	window.onscroll = function () {
		percentScroll =
			window.pageYOffset /
			(document.body.offsetHeight - window.innerHeight);

		$(path).each(function () {
			this.style.strokeDashoffset = Math.floor(
				this.getTotalLength() * (1 - percentScroll)
			);
		});
	}; */

	/*     var path = document.querySelector('.line-animated path');

      window.onload = function(){
        path.style.strokeDashoffset = '988.01';
        lineDraw();
        setInterval(lineDraw, 5000); 
      };


      function lineDraw(){
      var length = path.getTotalLength();
      path.style.transition = path.style.WebkitTransition =
        'none';

      path.style.strokeDasharray = length + ' ' + length; 
      path.style.strokeDashoffset = length;
      path.getBoundingClientRect();
      path.style.transition = path.style.WebkitTransition =
        'stroke-dashoffset 5s ease-in-out';
      path.style.strokeDashoffset = '0';
      };
 */
});
