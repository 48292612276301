

jQuery(document).ready(function ($) {


/* 	$('.ancre a').click(function () {
		$('html, body').animate({
		  scrollTop: $("#").offset().top - 100
		}, 2000);
	}); */

	/* anchor page implantation */

	
	$(function() {

		if ($(window).width() > 960) {
			$('.ancre a').click(function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top-200
				}, 1000);
				return false;
				}
			}
			});
		}
	  });


/* implantations filter */

	/* clique region picto map active*/

	/* FRANCE */

	const preselectionFr = $('#france .filtre').data('preselection');

	if (preselectionFr) {
		var $gridFr = $('#france .grid').isotope({
			// options
			itemSelector: '.grid-item',
			//layoutMode: 'fitRows',
			resizable: false,
			percentPosition: true,
			layoutMode: 'masonry',
			masonry: {
				columnWidth: '.grid-item',
			},
			filter: '.' + preselectionFr,
		});
	} else {
		var $gridFr = $('#france .grid').isotope({
			// options
			itemSelector: '.grid-item',
			//layoutMode: 'fitRows',
			resizable: false,
			percentPosition: true,
			layoutMode: 'masonry',
			masonry: {
				columnWidth: '.grid-item',
			},
		});
	}

	/* 	var $gridFr = $('#france_localisation .grid').isotope({
		// options
		itemSelector: '.grid-item',
		//layoutMode: 'fitRows',
		resizable: false,
		percentPosition: true,
		layoutMode: 'masonry',
		masonry: {
			columnWidth: '.grid-item',
		},
	}); */

	$('#france .filtres  .filtre').on('click', function () {
		var filterValueFr = $(this).attr('data-filter');

		$gridFr.isotope({
			// filter: '.' + $(this).attr('class')
			filter: filterValueFr,
		});
	});

	/* $('#france .localisation').click(function () {
		$('#france .localisation').removeClass('active');
		$(this).addClass('active');
	}); */

	/* EUROPE */

	const preselectionEu = $('#europe .filtre').data('preselection');

	if (preselectionEu) {
		var $gridEu = $('#europe .grid').isotope({
			// options
			itemSelector: '.grid-item',
			//layoutMode: 'fitRows',
			resizable: false,
			percentPosition: true,
			layoutMode: 'masonry',
			masonry: {
				columnWidth: '.grid-item',
			},
			filter: '.' + preselectionEu,
		});
	} else {
		var $gridEu = $('#europe .grid').isotope({
			// options
			itemSelector: '.grid-item',
			//layoutMode: 'fitRows',
			resizable: false,
			percentPosition: true,
			layoutMode: 'masonry',
			masonry: {
				columnWidth: '.grid-item',
			},
		});
	}

	/* 	var $gridEu = $('#europe .grid').isotope({
		// options
		itemSelector: '.grid-item',
		//layoutMode: 'fitRows',
		resizable: false,
		percentPosition: true,
		layoutMode: 'masonry',
		masonry: {
			columnWidth: '.grid-item',
		},
	}); */

	$('#europe   .filtre').on('click', function () {
		var filterValueEu = $(this).attr('data-filter');

		$gridEu.isotope({
			/* filter: '.' + $(this).attr('class') */
			filter: filterValueEu,
		});

		//  var LocalisationValueEu = $(this).attr('data-filter');
	});

	$('#europe .localisation').click(function () {
		$('#europe .localisation').removeClass('active');
		$(this).addClass('active');
	});

	/* CHINE */

	var $gridCh = $('#chine_localisation .grid').isotope({
		// options
		itemSelector: '.grid-item',
		//layoutMode: 'fitRows',
		resizable: false,
		percentPosition: true,
		layoutMode: 'masonry',
		masonry: {
			columnWidth: '.grid-item',
		},
	});

	$('#chine_localisation   .filtre').on('click', function () {
		var filterValueCh = $(this).attr('data-filter');

		$gridCh.isotope({
			/* filter: '.' + $(this).attr('class') */
			filter: filterValueCh,
		});
	});

	$('#chine .localisation').click(function () {
		$('#chine .localisation').removeClass('active');
		$(this).addClass('active');
	});

	$('.filtre').click(function () {
		$('.filtre').removeClass('active');
		$(this).addClass('active');
    });
    
});
