import './scss/style.scss';

import '../node_modules/uikit/dist/js/uikit.min';
import '../node_modules/bootstrap/js/dist/modal';
import '../node_modules/bootstrap/js/dist/collapse';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Swiper from 'swiper';
import './js/animations';
import './js/maps';

/* import TweenMax from "gsap/TweenMax"; */

jQuery(document).ready(function ($) {
	if (window.devicePixelRatio !== 1) {
		let scaleValue = 1.4 / window.devicePixelRatio;
		$('body').addClass('zoomdevice');
	}

	/* header */
	$(window).scroll(function () {
		$('header.header').toggleClass('scrolled', $(window).scrollTop() > 100);
		$('.js-scrollTo').toggleClass('scrolled', $(window).scrollTop() > 1000);

		if ($(window).scrollTop() > 100) {
			if ($('.navbar-collapse.show').length) {
				$('.navbar-toggler').trigger('click');
			}
		} else {
			if ($(window).width() > 960) {
				if (!$('.home .navbar-collapse.show').length) {
					$('.home .navbar-toggler').trigger('click');
				}
			}
		}
	});

	// $('li.menu-item-6609 > a').on('click', function(event){
	// 	event.preventDefault();
	// });

	const $menu = $('#nav-main');
	$(document).mouseup((e) => {
		if (
			!$menu.is(e.target) && // if the target of the click isn't the container...
			$menu.has(e.target).length === 0
		) {
			// ... nor a descendant of the container
			if ($('.navbar-collapse.show').length) {
				$('.navbar-toggler').trigger('click');
			}
		}
	});

	$(function () {
		if ($(window).width() > 960) {
			$('.home .navbar-toggler').trigger('click');
		}
	});

	/* encre scroll down */
	$('a.scroll-down').on('click', function () {
		var page = $(this).attr('href');
		var speed = 750;
		$('html, body').animate(
			{ scrollTop: $(page).offset().top - 100 },
			speed
		);
		return false;
	});

	const preselection = $('#secteur').data('preselection');

	if (preselection) {
		var $gridRealisations = $('.grid-realisations').isotope({
			// options
			itemSelector: '.grid-item',
			percentPosition: true,
			resizable: true,
			layoutMode: 'packery',
			packery: {
				gutter: 20,
				horizontalOrder: true,
				columnWidth: '.grid-sizer',
			},
			filter: '.' + preselection,
		});
	} else {
		var $gridRealisations = $('.grid-realisations').isotope({
			// options
			itemSelector: '.grid-item',
			resizable: true,
			percentPosition: true,
			layoutMode: 'packery',
			packery: {
				gutter: 20,
				horizontalOrder: true,
				columnWidth: '.grid-sizer',
			},
		});
	}

	// layout Isotope after each image loads
	$gridRealisations.imagesLoaded().progress(function () {
		$gridRealisations.isotope('layout');
	});

	$('.filtres-realisations  li').on('click', function () {
		$gridRealisations.isotope({
			filter: '.' + $(this).attr('class'),
		});
		//test
		$gridRealisations.isotope('layout');
		//
		$('.filtres-realisations .active').removeClass('active');
		$(this).addClass('active');

		localStorage.setItem('selectionActive', $(this).attr('data-filter'));
	});

	let urlParams = new URLSearchParams(window.location.search);
	const back = urlParams.get('back');
	if (back != 1) {
		localStorage.setItem('selectionActive', '.all');
	}

	var $grid = $('.grid').isotope({
		itemSelector: '.grid-item',

		resizable: false,
		percentPosition: true,
		layoutMode: 'masonry',
		masonry: {
			columnWidth: '.grid-item',
		},
		getSortData: {
			name: '.name',
			date: '.date parseInt',
		},
	});

	$('.filtres  option').on('click', function () {
		var sortValue = $('#choix_tri option:selected').val();
		const options = {};

		if (sortValue) {
			options.sortBy = sortValue;
		}

		console.log(options);
		$grid.isotope(options);
	});

	$('#actualites .filtres  li').on('click', function () {
		$grid.isotope({
			filter: '.' + $(this).attr('class'),
		});
		$('.filtres .active').removeClass('active');
		$(this).addClass('active');

		localStorage.setItem('selectionActive', $(this).attr('data-filter'));
	});

	/* Page contact 1er onglet actif */
	if (
		$('.form-contact .nav-tabs > li:first-child > a') &&
		$('.form-contact .nav-tabs > li:first-child > a')[0]
	) {
		$('.form-contact .nav-tabs > li:first-child > a')[0].click();
	}

	//preselection formulaire projet
	const selectionProjet = $('.formulaire-projet').data('select-projet');

	if (selectionProjet) {
		const entreprise = $('label#label_1_7_0');
		const investisseur = $('label#label_1_7_1');
		const promoteur = $('label#label_1_7_2');
		const collectivite = $('label#label_1_7_3');

		let selection = '';
		switch (selectionProjet) {
			case 'entreprise':
				selection = entreprise;
				break;
			case 'a-company':
				selection = entreprise;
				break;
			case 'eine-firma':
				selection = entreprise;
				break;
			case 'investisseur':
				selection = investisseur;
				break;
			case 'an-investor':
				selection = investisseur;
				break;
			case 'ein-investor':
				selection = investisseur;
				break;
			case 'promoteur':
				selection = promoteur;
				break;
			case 'a-promoter':
				selection = promoteur;
				break;
			case 'ein-projektentwickler':
				selection = promoteur;
				break;
			case 'collectivite':
				selection = collectivite;
				break;
			case 'a-local-authority':
				selection = collectivite;
				break;
		}
		$(selection).trigger('click');
	}

	//preselection formulaire contact
	const selectionContact = $('.form-contact').data('select-contact');

	if (selectionContact) {
		$('.form-contact > ul > li > a').removeClass('active');
		$('.form-contact .tab-content .tab-pane').removeClass('active');
		$(`.${selectionContact}`).addClass('active');
		$(`#${selectionContact}`).addClass('active');
	}

	$('#field_1_29 input ,#field_1_28 input').on('click', function () {
		$('#field_1_29 input ,#field_1_28 input').prop('checked', false);
		$(this).prop('checked', true);
	});

	$('#field_5_11 input ,#field_5_30 input').on('click', function () {
		$('#field_5_11 input ,#field_5_30 input').prop('checked', false);
		$(this).prop('checked', true);
	});
	$('#field_1_11 input ,#field_1_30 input').on('click', function () {
		$('#field_1_11 input ,#field_1_30 input').prop('checked', false);
		$(this).prop('checked', true);
	});
	$('#field_1_11 input ,#field_1_29 input').on('click', function () {
		$('#field_1_11 input ,#field_1_29 input').prop('checked', false);
		$(this).prop('checked', true);
	});

	if ($('.breadcrumbs span span a:contains("someText")').length > 0) {
		$('#somediv').addClass('thisClass');
	}
	var bread = $('.breadcrumbs span span span a');
	if (bread.text() === 'I nostri progetti') {
		bread.text('Progetti');
	}
	if (bread.text() === 'Our Projects') {
		bread.text('Projects');
	}
});
